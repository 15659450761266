import React from "react"

import { useTranslation } from "@bounce/i18n"

import { Modal } from "../../Modal/Modal"
import type { ReviewCardProps } from "../ReviewCard/ReviewCard"
import { ReviewCard } from "../ReviewCard/ReviewCard"
import type { Review } from "@/utils/reviews"

export type ReviewsModalProps = {
  isOpen: boolean
  onClose: () => void
  onClickReviewCard: (reviewer: string) => void
  className?: string
  reviews: Review[]
  reviewCardProps?: Pick<ReviewCardProps, "country" | "subtitle">
}

export const ReviewsModal = ({
  isOpen,
  onClose,
  onClickReviewCard,
  className,
  reviews,
  reviewCardProps,
}: ReviewsModalProps) => {
  const [t] = useTranslation()

  return (
    <Modal
      isDOMVisible={true}
      isOpen={isOpen}
      onClose={onClose}
      className={className}
      title={<h5 className="title5 text-black">{t("cmp.reviewsModal.title", "What our customers say")}</h5>}>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {reviews.map(review => (
          <div key={review.id} onClick={() => onClickReviewCard(review.user.fullName)}>
            <ReviewCard review={review} className="h-full" {...reviewCardProps} />
          </div>
        ))}
      </div>
    </Modal>
  )
}
