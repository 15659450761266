import classNames from "classnames"
import React, { useRef, useState } from "react"
import type { SwiperRef } from "swiper/react"
import { Swiper, SwiperSlide } from "swiper/react"

import { Analytics, ModalEvents } from "@bounce/analytics"
import { Paragraph1, Title2 } from "@bounce/design-system"
import { useTranslation } from "@bounce/i18n"
import { Button, Link, SliderNavigation } from "@bounce/web-components"

import { useReviews } from "./useReviews"
import type { ReviewCardProps } from "../ReviewCard/ReviewCard"
import { ReviewCard } from "../ReviewCard/ReviewCard"
import { ReviewsModal } from "../ReviewsModal/ReviewsModal"
import { useSectionViewed } from "@/hooks/useSectionViewed"
import { InteractionEvents } from "@/libs/analytics/events"
import { Product } from "@/types"
import type { Review } from "@/utils/reviews"

export type ReviewsSliderProps = {
  cityId?: string
  className?: string
  description?: string
  minimumReviewsCount?: number
  name: string
  country?: string
  product?: Product
  reviews?: Review[]
  showAppReviews?: boolean
  showSeeAllReviews?: boolean
  cityName?: string
  title?: string
  titleLevel?: 2 | 3
  reviewModalCardProps?: Pick<ReviewCardProps, "country" | "subtitle">
}

export const ReviewsSlider = ({
  cityId,
  className,
  description,
  minimumReviewsCount = 1,
  name,
  cityName,
  country,
  product = Product.LuggageStorage,
  reviews,
  showAppReviews = false,
  showSeeAllReviews,
  title,
  titleLevel = 2,
  reviewModalCardProps,
}: ReviewsSliderProps) => {
  const [t] = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const reviewsHook = useReviews({ product, showAppReviews })
  const swiperRef = useRef<SwiperRef>(null)
  const { sectionRef } = useSectionViewed({ name })

  const totalReviewToShow = !!reviews?.length && reviews.length >= minimumReviewsCount ? reviews : reviewsHook
  const reviewsForSlider = totalReviewToShow.slice(0, 19)

  const next = () => {
    swiperRef.current?.swiper.slideNext()
    Analytics.track(InteractionEvents.NextReviewPressed)
  }

  const prev = () => {
    swiperRef.current?.swiper.slidePrev()
  }

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()
    setIsModalOpen(true)
    Analytics.track(ModalEvents.ToggleModal, { name: "Reviews", visible: true })
  }

  const onClose = () => {
    setIsModalOpen(false)
    Analytics.track(ModalEvents.ToggleModal, { name: "Reviews", visible: false })
  }

  const onClickReviewCard = (reviewer: string) => {
    Analytics.track(InteractionEvents.ReviewCardPressed, { reviewer })
  }

  return (
    <>
      <section
        ref={sectionRef}
        className={classNames("relative mx-auto flex w-full max-w-screen-xl flex-col", className)}>
        <Title2 level={titleLevel} className="px-6 text-black">
          {title || t("cmp.reviewsSlider.millionsOfCustomers", "Millions of customers all over the world")}
        </Title2>
        {!!description && (
          <Paragraph1 className="mt-2 px-6 text-neutral-800 md:mt-2.5 xl:mt-3">{description}</Paragraph1>
        )}
        <div className="mt-6">
          <Swiper
            ref={swiperRef}
            slidesPerView="auto"
            spaceBetween={32}
            slidesOffsetBefore={18}
            slidesOffsetAfter={24}
            watchSlidesProgress
            rewind>
            {reviewsForSlider.map(review => (
              <SwiperSlide
                key={review.id}
                className="!h-auto max-w-[284px] md:!min-w-[230px] md:!max-w-[360px]"
                onClick={() => onClickReviewCard(review.user.fullName)}>
                <ReviewCard review={review} country={country} className="h-full max-w-[284px] md:max-w-[360px]" />
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className={classNames(
              "mt-7 flex w-full flex-row items-center justify-end px-6 md:mt-12",
              showSeeAllReviews && "justify-between pl-0",
            )}>
            {showSeeAllReviews && (
              <Button
                as={Link}
                onClick={onClick}
                className="m-0 !pr-2 text-primary-700 xs:px-5"
                href={{
                  pathname: "/reviews",
                  query: { cityId },
                }}
                title={
                  cityName
                    ? t("cmp.reviewsSlider.luggageStorageInCity", "Luggage Storage in {{cityName}}", { cityName })
                    : ""
                }
                variant="ghost">
                {t("cmp.reviewsSlider.seeAllReviews", "See all reviews")}
              </Button>
            )}
            <SliderNavigation onClickNext={next} onClickPrev={prev} sliderItemSize={reviewsForSlider.length} />
          </div>
        </div>
      </section>

      <ReviewsModal
        reviews={totalReviewToShow}
        isOpen={isModalOpen}
        onClose={onClose}
        onClickReviewCard={onClickReviewCard}
        reviewCardProps={reviewModalCardProps}
      />
    </>
  )
}
