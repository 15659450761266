import classNames from "classnames"

import { SliderNavigationButton } from "../SliderNavigationButton/SliderNavigationButton"

export type SliderNavigationProps = {
  onClickPrev?: () => void
  onClickNext?: () => void
  className?: string
  sliderItemSize: number
  minSliderItemSizeToShow?: number
}

export const SliderNavigation = ({
  className,
  onClickPrev,
  onClickNext,
  sliderItemSize,
  minSliderItemSizeToShow = 1,
}: SliderNavigationProps) =>
  sliderItemSize > minSliderItemSizeToShow ? (
    <div className={classNames("slider__navigation", className)}>
      <SliderNavigationButton onClick={onClickPrev} direction="previous" />
      <SliderNavigationButton onClick={onClickNext} direction="next" />
    </div>
  ) : null
